import React from "react";

const InputProjectContent = ({value, onChange}) => {
  return (
    <textarea
      name="content"
      rows="15"
      placeholder="Description du projet"
      value={value}
      onChange={onChange}
      required
    />
  );
};

export default InputProjectContent;
