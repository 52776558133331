import { createSlice } from "@reduxjs/toolkit";
import { deleteRequest, getRequest, postRequest } from "../../api/api";
import { decodeHtmlEntities } from "../../utils/decodedHtml";

export const fetchProjectsInformations =
  (page = 1) =>
  async (dispatch, getState) => {
    try {
      const loading = getState().project.loading;
      if (loading) {
        return;
      }
      dispatch(startLoading());
      
      const { status, result } = await getRequest(
        `projects/all?page=${page}&pageSize=3`
      );

      if (status === 200) {
        
        const decodedData = result.data.projects.map((project) => ({
          ...project,
          title: decodeHtmlEntities(project.title),
          content: decodeHtmlEntities(project.content),
        }));

        const totalProjects = result.data.totalItems;

        const totalPages = Math.ceil(
          totalProjects / getState().project.pageSize
        );

        dispatch(setTotalPages(totalPages));
        dispatch(setProjects(decodedData));
        dispatch(setCurrentPage(page))

      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(stopLoading());
    }
  };

export const createNewProject =
  (title, content, created_at) => async (dispatch) => {
    try {
      const { status, result } = await postRequest(
        "projects/",
        { title, content, created_at },
        true
      );
      if (status === 200) {
        dispatch(addProject(result.data));
        dispatch(fetchProjectsInformations());
      }
    } catch (error) {
      console.error({ message: "An error occured", error });
    }
  };

export const removeProject = (id) => async (dispatch) => {
  try {
    const { status, result } = await deleteRequest(`projects/${id}`, true);
    if (status === 200) {
      dispatch(deleteProject(result));
    }
  } catch (error) {
    console.error(error);
  }
};

export const projectSlice = createSlice({
  name: "project",
  initialState: {
    loading: false,
    title: "",
    content: "",
    date: "",
    projects: [],
    totalPages: 1,
    currentPage: 1,
    pageSize: 3,
  },
  reducers: {
    startLoading: (state) => {
      return { ...state, loading: true };
    },
    stopLoading: (state) => {
      return { ...state, loading: false };
    },
    setProjects: (state, action) => {
      return { ...state, projects: action.payload };
    },
    setCurrentPage: (state, action) => {
      return { ...state, currentPage: action.payload };
    },
    setTotalPages: (state, action) => {
      return { ...state, totalPages: action.payload };
    },
    addProject: (state, action) => {
      const newProjects = [...state.projects];
      newProjects.push(action.payload);
      return { ...state, projects: newProjects };
    },
    deleteProject: (state, action) => {
      const updatedProjects = [...state.projects];
      updatedProjects.filter((project) => project.id !== action.payload);
      return { ...state, projects: updatedProjects };
    },
  },
});

export const {
  startLoading,
  stopLoading,
  setProjects,
  addProject,
  deleteProject,
  setTotalPages,
  setCurrentPage,
  //setImageMapping
} = projectSlice.actions;
export default projectSlice.reducer;
