import React from "react";
import mc from "./gallery.module.scss";
import { Helmet } from "react-helmet";
import { Suspense } from "react";
import Logo from "../../../img/logo192.png";
import { AnimatePresence, motion } from "framer-motion";

const Slider = React.lazy(() => import("./items/Slider.galeries"));

const Gallery = () => {
  const ogImageUrl = `${window.location.origin}${Logo}`;

  return (
    <>
      <Helmet>
        <title>Galeries | TJ Peinture</title>
        <meta
          name="description"
          content="Découvrez les projets réalisés par Tj Peinture ! Les peintures extérieurs, intérieurs, façades etc."
        />
        <meta
          name="keywords"
          content="TJ Peinture, peinture, projets réalisés, rénovation, décoration intérieure, décoration extérieure"
        />
        {/* Meta robots */}
        <meta name="robots" content="index, unfollow" />
        {/* Open Graph balises for social networks */}

        <meta property="og:image" content={ogImageUrl} />
        <meta
          property="og:description"
          content="Galeries | Peinture Intérieure - Peinture extérieure - Peinture Bâtiment - Rénovation , ..."
        />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:title" content="TJ Peinture - Galeries" />
      </Helmet>
      <AnimatePresence>
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="gallery"
          className={` ${mc.gallery} container`}
          id="top"
          role="main"
        >
          <div className={mc["title"]}>
            <h2>Réalisations</h2>
          </div>
          <Suspense fallback={<div className="loader"></div>}>
            <Slider />
          </Suspense>
        </motion.main>
      </AnimatePresence>
    </>
  );
};

export default Gallery;
