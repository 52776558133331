import React, { useEffect, useState } from "react";
import EditBtn from "../../../tools/buttons/EditBtn.tools";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSectionsInformations,
  setContent,
} from "../../../../redux/reducers/sections.slice";
import { getImageRequest, putRequest } from "../../../../api/api";
import SendBtn from "../../../tools/buttons/SendBtn";
import Modal from "../../../tools/Modal.tools";
import { closeModal, openModal } from "../../../../redux/reducers/modals.slice";
import mcdashboard from "../dashboard.module.scss";
import AddPictureBtn from "../../../tools/buttons/AddPictureBtn.tools";
import { isImageFile } from "../../../../utils/validate-input.utils";
import {
  removePicture,
  uploadPictures,
} from "../../../../redux/reducers/pictures.slice";
import DeleteBtn from "../../../tools/buttons/DeleteBtn.tools";
import { Suspense } from "react";
import { AnimatePresence, motion } from "framer-motion";
const LazyImage = React.lazy(() => import("../items/sections/LazyImage"));

const MySections = () => {
  const [updateMessage, setUpdateMessage] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [newContent, setNewContent] = useState("");
  const [sectionSelect, setSectionSelect] = useState("");
  const [imageMapping, setImageMapping] = useState({});
  const [updateMessageImage, setUpdateMessageImage] = useState(null);
  const [id, setId] = useState(null);
  const [image, setImage] = useState("");
  const isEditModalOpen = useSelector((state) => state.modals.editSection);
  const isDeleteModalOpen = useSelector(
    (state) => state.modals.deletePictureCarousel
  );
  const isCarouselModalOpen = useSelector(
    (state) => state.modals.pictureCarousel
  );
  const dispatch = useDispatch();
  const { sections, loading } = useSelector((store) => store.section);

  ////////////////////////////////////////////////////////////////////

  const updateSection = async (id, updateField) => {
    try {
      const response = await putRequest(`sections/${id}`, updateField, true);

      if (response.status === 200) {
        setUpdateMessage(
          "Les informations de la section ont été mis à jour avec succès"
        );
        setStatusMessage("success-message");
        setContent(newContent);
        setTimeout(() => {
          setUpdateMessage("");
          setUpdateMessageImage("");
          setStatusMessage("");
        }, 10000);
        dispatch(fetchSectionsInformations());
        handleCloseModal();
      } else {
        handleCloseModal();
        setStatusMessage("error-message");
        setUpdateMessage("Les informations n'ont pas pu être changé");
        setTimeout(() => {
          setUpdateMessage("");
          setUpdateMessageImage("");
          setStatusMessage("");
        }, 10000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Manage modals
  const handleCloseModal = () => {
    dispatch(closeModal("editSection"));
  };
  const handleOpenModal = (sectionId) => {
    dispatch(openModal("editSection"));

    const selectedSections = sections.find(
      (section) => section.id === sectionId
    );
    setSectionSelect(selectedSections.title);
    setNewContent(selectedSections.content);
    setId(selectedSections.id);
  };
  //--Add image carousel
  const handleCloseModalCarousel = () => {
    dispatch(closeModal("pictureCarousel"));
  };
  const handleOpenModalCarousel = () => {
    dispatch(openModal("pictureCarousel"));
  };
  //--Delete image carousel
  const handleOpenModalConfirmation = (id) => {
    setId(id);
    dispatch(openModal("deletePictureCarousel"));
  };
  const handleCloseModalConfirmation = () => {
    dispatch(closeModal("deletePictureCarousel"));
  };
  const handleDeletePicture = async (id) => {
    await dispatch(removePicture(id));
    dispatch(fetchSectionsInformations());
  };
  const handleConfirmDeletePicture = async () => {
    if (id) {
      await handleDeletePicture(id);
      handleCloseModalConfirmation("deletePictureCarousel");
    }
  };
  //--
  const handleSectionSubmit = (e) => {
    e.preventDefault();
    updateSection(id, { content: newContent });
  };
  //Manage carousel
  const handleImageChange = (e) => {
    setImage(Array.from(e.target.files));
  };
  const handleSubmitAddPicture = async (e) => {
    e.preventDefault();
    //Check if the file isn't empty, if empty : prevents the form from being sent or verify the mimetype;
    const fileSizeLimitMb = 1000000; // 1Mo will be accepted

    for (let i = 0; i < image.length; i++) {
      if (image[i].size > fileSizeLimitMb) {
        setUpdateMessageImage("L'image est trop lourde, 1Mo autorisé");
        setStatusMessage("error-message");
        setTimeout(() => {
          setUpdateMessage("");
          setUpdateMessageImage("");
          setStatusMessage("");
        }, 10000);
        return;
      }
    }
    if (!image || !isImageFile(image)) {
      setStatusMessage("error-message");
      setUpdateMessageImage(
        "Veuillez sélectionner un fichier d'image valide !"
      );
      setUpdateMessage("L'image n'a pas pu être envoyé !");
      setTimeout(() => {
        setUpdateMessage("");
        setUpdateMessageImage("");
        setStatusMessage("");
      }, 10000);

      return;
    }

    try {
      const idSection = 1;
      for (let i = 0; i < image.length; i++) {
        const response = await dispatch(
          uploadPictures(image[i], null, idSection)
        );
        if (response.status === 400 || response.status === 500) {
          setUpdateMessage(
            "Erreur lors de l'envoi de l'image ! Vérifiez le type de fichier, la taille ou le nom de l'image."
          );
          setUpdateMessageImage(
            "Erreur lors de l'envoi de l'image ! Vérifiez le type de fichier, la taille ou le nom de l'image."
          );
          setStatusMessage("error-message");
          setImage("");
          setTimeout(() => {
            setUpdateMessage("");
            setUpdateMessageImage("");
            setStatusMessage("");
          }, 10000);
        } else {
          dispatch(fetchSectionsInformations());
          setUpdateMessage("Image envoyée avec succès !");
          setUpdateMessageImage("");
          setStatusMessage("success-message");
          setImage("");
          setTimeout(() => {
            setUpdateMessage("");
            setUpdateMessageImage("");
            setStatusMessage("");
          }, 10000);
        }
      }
      handleCloseModalCarousel();
    } catch (error) {
      console.error(error);
      setUpdateMessage("Une erreur est survenue lors de l'envoi de l'image.");
      setStatusMessage("error-message");
    }
  };

  //Manage modals content
  const modalTitle = {
    editSection: `Modifier la section "${sectionSelect}`,
    editCarousel: "Ajouter une image au carousel",
    deletePicture: "Supprimer une image",
  };
  const modalContent = {
    editSection: (
      <form onSubmit={handleSectionSubmit}>
        <textarea
          name="content"
          id=""
          rows="15"
          value={newContent}
          onChange={(e) => setNewContent(e.target.value)}
          required
        />
        <SendBtn />
      </form>
    ),
    editCarousel: (
      <form onSubmit={handleSubmitAddPicture}>
        <input
          type="file"
          name="file"
          accept="image/*"
          multiple
          onChange={handleImageChange}
        />
        <p className={`message ${statusMessage}`}>{updateMessageImage}</p>
        <SendBtn />
      </form>
    ),
    confirmDelete: (
      <>
        <p>Êtes-vous sûr de vouloir supprimer cette image ?</p>
        <button className="btn-delete" onClick={handleConfirmDeletePicture}>
          Supprimer
        </button>
      </>
    ),
  };

  useEffect(() => {
    dispatch(fetchSectionsInformations());
  }, []);

  useEffect(() => {
    const getPicturesSections = async () => {
      const newImageMapping = {};
      // const section = sections[0];
      if (sections && sections[0] && sections[0].Pictures) {
        for (let i = 0; i < sections[0].Pictures.length; i++) {
          const picture = sections[0].Pictures[i];
          const image = (await getImageRequest(picture.id)).result;
          newImageMapping[picture.id] = image;
        }
      }
      setImageMapping(newImageMapping);
    };
    getPicturesSections();
  }, [sections]);
  return (
    <AnimatePresence>
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key="sections-dashboard"
        className={mcdashboard["section-content"]}
      >
        <p className={` message ${statusMessage}`}>{updateMessage}</p>
        <table className={mcdashboard["table-sections"]}>
          <caption>Page d'accueil & Page services proposés</caption>
          <tbody>
            {loading ? (
              <tr>
                <th>Chargement des sections ...</th>
              </tr>
            ) : (
              <>
                <tr>
                  <th>Slider page d'accueil</th>
                  <td className="thumbnail-wrapp">
                    {sections[0] &&
                      sections[0].Pictures &&
                      sections[0].Pictures.map((picture) => (
                        <div className="thumbnail-container" key={picture.id}>
                          <Suspense fallback={<div className="loader"></div>}>
                            <LazyImage
                              src={imageMapping[picture.id]}
                              alt={picture.file_name}
                            />
                          </Suspense>
                          {/* <img
                          key={picture.id}
                          src={imageMapping[picture.id]}
                          alt={picture.file_name}
                          className="thumbnail-picture"
                        /> */}
                          <DeleteBtn
                            onClick={() =>
                              handleOpenModalConfirmation(picture.id)
                            }
                          />
                        </div>
                      ))}
                  </td>
                  <td data-label="Actions">
                    <AddPictureBtn onClick={() => handleOpenModalCarousel()} />
                  </td>
                </tr>
                {sections.map((section) => (
                  <tr key={section.id} id={section.id}>
                    <th data-label="Titre">{section.title}</th>
                    <td data-label="Description">{section.content}</td>
                    <td data-label="Actions">
                      <EditBtn onClick={() => handleOpenModal(section.id)} />
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        {isEditModalOpen && (
          <section className="modal-container">
            <Modal
              title={modalTitle.editSection}
              content={modalContent.editSection}
              onClose={() => handleCloseModal("editSection")}
            />
          </section>
        )}

        {isCarouselModalOpen && (
          <section className="modal-container">
            <Modal
              title={modalTitle.editCarousel}
              content={modalContent.editCarousel}
              onClose={() => handleCloseModalCarousel("pictureCarousel")}
            />
          </section>
        )}

        {isDeleteModalOpen && (
          <section className="modal-container">
            <Modal
              title={modalTitle.deletePicture}
              content={modalContent.confirmDelete}
              onClose={() =>
                handleCloseModalConfirmation("deletePictureCarousel")
              }
            />
          </section>
        )}
      </motion.section>
    </AnimatePresence>
  );
};

export default MySections;
