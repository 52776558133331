import React from "react";

const InputProjectDate = ({value, onChange}) => {
  return (
    <>
      <label htmlFor="">Date de réalisation : </label>
      <input type="date" value={value} onChange={onChange} name="date" title="Date de réalisation" alt="Date de réalisation"/>
    </>
  );
};

export default InputProjectDate;
