import { createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../api/api";
import { decodeHtmlEntities } from "../../utils/decodedHtml";

export const decodeAndSetContent = (encodedText) => async (dispatch) => {
    try {
        const decodedText = decodeHtmlEntities(encodedText); 
        dispatch(setDecodedContent(decodedText));
    } catch (error) {
        console.error("An error occurred while decoding and setting content:", error);
    }
};

export const fetchSectionsInformations = () => async (dispatch, getState) => {
    try {
      const loading = getState().section.loading;
      if (loading) {
        return;
      }
      dispatch(startLoading());
  
      const { status, result } = await getRequest("sections/all", true);
  
      if (status === 200) {
        const decodedData = result.data.map(section => ({
          ...section,
          title : decodeHtmlEntities(section.title),
          content: decodeHtmlEntities(section.content) 
        }));
        dispatch(setSections(decodedData));
      }
    } catch (error) {
      console.error({ message: "An error occurred during data loading" }, error);
    } finally {
      dispatch(stopLoading());
    }
  };


export const sectionSlice = createSlice({
    name : "section",
    initialState : {
        loading : false,
        title : "",
        content : "",
        decodedContent : "",
        sections : []
    },
    reducers : {
        startLoading : (state) => {
            return { ...state, loading : true}
        }, 
        stopLoading : (state) => {
            return { ...state, loading : false}
        },
        setTitle : (state, action) => {
            return {...state, title : action.payload}
        },
        setDecodedContent: (state, action) => {
            return { ...state, decodedContent: action.payload };
        },
        setContent : (state, action) => {
            return { ...state, content : action.payload}
        },
        setSections : (state, action) => {
            return { ...state, sections : action.payload}
        }
    }
})

export const {startLoading, stopLoading, setTitle, setContent, setSections, setDecodedContent} = sectionSlice.actions;
export default sectionSlice.reducer;