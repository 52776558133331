import React from 'react';
import {AiFillRocket} from 'react-icons/ai'

const Anchor = () => {
    return (
        <a href="#top" className="anchor-link" aria-label="Remonter en haut de la page actuelle">
          <AiFillRocket className="anchor-icon"/>
        </a>
    );
};

export default Anchor;