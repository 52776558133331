import React from "react";
import mc from "./footer.module.scss";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={mc["footer"]} role="contentinfo">
      <NavLink
        to="mention-legales"
        className={mc["legal-notice"]}
        aria-label="Redirection vers la page Mentions légales"
      >
        Mentions légales
      </NavLink>
      <p>&copy; 2024 - TjPeinture</p>
      <div className={mc["social-network"]}>
        <a
          href="https://www.facebook.com/tjpeinture"
          title="Facebook de TjPeinture"
          alt="Facebook de TjPeinture"
        >
          <FaFacebookSquare className={mc["social-network-icons"]} />
        </a>
        <a
          href="https://www.instagram.com/tjpeinture/"
          title="Instagram de TjPeinture"
          alt="Instagram de TjPeinture"
        >
          <FaInstagramSquare className={mc["social-network-icons"]} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
