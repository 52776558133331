import React from "react";
import mc from "./services.module.scss";
import peinture from "./items/peinture.jpg";
import revetementMural from "./items/revetement-mural.jpg";
import revetementSol from "./items/sol.jpg";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchSectionsInformations } from "../../../redux/reducers/sections.slice";
import Logo from "../../../img/logo192.png";
import { motion, AnimatePresence } from "framer-motion";

const Services = () => {
  const dispatch = useDispatch();
  // UseSelector to retrieve the section information from the Redux store
  const { sections, loading } = useSelector((store) => store.section);

  // UseEffect to launch the fetchSectionsInformation action when the page is loaded
  useEffect(() => {
    dispatch(fetchSectionsInformations());
  }, []);
  return (
    <>
      <Helmet>
        <title>Services proposés | TJ Peinture</title>
        <meta
          name="description"
          content="Retrouvez les services proposés par TJ Peinture | Peinture intérieure - Peinture Extérieure - Papier peint - Revêtement sol "
        />
        <meta
          name="keywords"
          content="TJ Peinture, peinture, services, entretien, rénovation, peinture intérieure, peinture extérieure, décoration"
        />

        {/* Meta robots */}
        <meta name="robots" content="index, unfollow" />
        {/* Open Graph balises for social networks */}

        <meta property="og:image" content={Logo} />
        <meta
          property="og:description"
          content="Services proposés | Peinture Intérieure - Peinture extérieure - Peinture Bâtiment - Rénovation , ..."
        />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:title" content="TJ Peinture - Services proposés" />
      </Helmet>

      <AnimatePresence>
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="services"
          className={` ${mc.services} container`}
          id="top"
          role="main"
        >
          <div className={mc["title"]}>
            <h2>Services</h2>
          </div>
          {/* Start of the "Peinture intérieure" section */}
          <section>
            {loading ? (
              //If loading displays a loading animation
              <div className="loader"></div>
            ) : sections.length === 0 ? (
              //If section is empty return a default section
              <article>
                <h4 className="highlighted-text">Peinture intérieure</h4>
                <p>
                  La <strong>peinture intérieure</strong> peut être utilisée
                  pour donner un aspect neuf et rafraîchissant à une pièce, pour
                  créer une ambiance chaleureuse et accueillante, ou encore pour
                  protéger les surfaces contre l'humidité, les moisissures et
                  les dommages causés par l'usure du temps.
                </p>

                <img
                  src={peinture}
                  alt="Peinture murale intérieure par TJ Peinture : triangles rose, gris clair et gris/noir foncé"
                  title="Peinture murale intérieure par TJ Peinture"
                  loading="lazy"
                />
              </article>
            ) : (
              //Otherwise returns the information of the section retrieved during the request to the server
              <article>
                <h4 className="highlighted-text">{sections[4]?.title}</h4>
                <p>{sections[4]?.content}</p>
                <img
                  src={peinture}
                  alt="Peinture murale intérieure par TJ Peinture : triangles rose, gris clair et gris/noir foncé"
                  title="Peinture murale intérieure par TJ Peinture"
                  loading="lazy"
                />
              </article>
            )}
          </section>
          {/* Enf of section */}
          {/* Start of the "Revêtement du sol" section */}
          <section>
            {loading ? (
              //If loading displays a loading animation
              <div className="loader"></div>
            ) : sections.length === 0 ? (
              //If section is empty return a default section
              <article>
                <h4 className="highlighted-text">Revêtement du sol</h4>
                <p>
                  Les <strong>revêtements de sol</strong> peuvent être utilisés
                  pour créer un effet visuel, pour protéger le sol contre les
                  rayures, les taches et l'usure, pour faciliter l'entretien ou
                  encore pour améliorer l'isolation thermique et acoustique
                  d'une pièce.
                </p>

                <img
                  src={revetementSol}
                  alt="Image libre de droit fournit par le site Pexels, photo de Skitterphoto: https://www.pexels.com/fr-fr/photo/1388944/ "
                  title="Revêtement du sol avec du plancher"
                  loading="lazy"
                />
              </article>
            ) : (
              //Otherwise returns the information of the section retrieved during the request to the server
              <article>
                <h4 className="highlighted-text">{sections[5]?.title}</h4>
                <p>{sections[5]?.content}</p>

                <img
                  src={revetementSol}
                  alt="Image libre de droit fournit par le site Pexels, photo de Skitterphoto: https://www.pexels.com/fr-fr/photo/1388944/ "
                  title="Revêtement du sol avec du plancher"
                  loading="lazy"
                />
              </article>
            )}
          </section>
          {/* End of section */}
          {/* Start of the "Revêtement mural" section */}
          <section>
            {loading ? (
              //If loading displays a loading animation
              <div className="loader"></div>
            ) : sections.length === 0 ? (
              //If section is empty return a default section
              <article>
                <h4 className="highlighted-text">Revêtement mural</h4>
                <p>
                  Les <strong>revêtements muraux</strong> peuvent être utilisés
                  pour créer un effet visuel, pour protéger les murs contre les
                  chocs, les rayures et les taches, pour faciliter l'entretien
                  ou encore pour améliorer l'isolation thermique et acoustique
                  d'une pièce. Les revêtements muraux peuvent être en papier
                  peint, en toile de verre, en peinture, en béton ciré, en
                  plâtre, etc.
                </p>
                <p className={mc["advertising"]}>
                  En manque d'inspiration ? L'
                  <a
                    href="https://atelier-morgans.fr/"
                    title="Site web Atelier Morgans"
                    aria-label="Aller sur le site web atelier-morgans"
                  >
                    Atelier MORGANS
                  </a>{" "}
                  vous propose des décorations murales sur mesure, adaptées à
                  vos envies !
                </p>

                <img
                  src={revetementMural}
                  alt="Revêtement du sol par TJ Peinture"
                  title="Revêtement du sol par TJ Peinture"
                  loading="lazy"
                />
              </article>
            ) : (
              //Otherwise returns the information of the section retrieved during the request to the server
              <article>
                <h4 className="highlighted-text">{sections[6]?.title}</h4>
                <p>{sections[6]?.content}</p>
                <p className={mc["advertising"]}>
                  En manque d'inspiration ? L'
                  <a
                    href="https://atelier-morgans.fr/"
                    title="Site web Atelier Morgans"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>Atelier MORGANS</strong>
                  </a>{" "}
                  vous propose des décorations murales sur mesure, adaptées à
                  vos envies !
                </p>

                <img
                  src={revetementMural}
                  alt="Peinture intérieure par TJ Peinture"
                  title="Revêtement mural réalisé par TjPeinture, papier peint floral dans une chambre"
                  loading="lazy"
                />
              </article>
            )}
          </section>
        </motion.main>
      </AnimatePresence>
    </>
  );
};

export default Services;
