import React from 'react';
import {AiFillEdit} from 'react-icons/ai'

const EditBtn = ({onClick}) => {
    return (
        <AiFillEdit onClick={onClick} className="icons-btn edit" />

    );
};

export default EditBtn;