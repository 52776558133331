import React from "react";
import mcmention from "./legal-notice.module.scss";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCompagnyInformations } from "../../../redux/reducers/compagny.slice";
import { fetchUserInformations } from "../../../redux/reducers/user.slice";
import { Helmet } from "react-helmet";
import { AnimatePresence, motion } from "framer-motion";

const LegalNotice = () => {
  const dispatch = useDispatch();
  //Select the "siret, adress, mobile, and email" properties from the Redux store. These properties are extracted from the "compagny" and "user" states
  const { siret, adress, mobile } = useSelector((store) => store.compagny);
  const { email } = useSelector((store) => store.user);

  useEffect(() => {
    //retrieves company and user information from the application store, and uses the Hook useEffect to execute effects asynchronously.
    dispatch(fetchCompagnyInformations());
    dispatch(fetchUserInformations());
  }, []);
  return (
    <>
      <Helmet>
        <title>Mentions légales et cookies | TJ Peinture</title>
        <meta name="description" content="Mentions légales et cookies" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <AnimatePresence>
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="legal-notice"
          className={mcmention["mentions-legales"]}
          role="main"
        >
          <section>
            <h2 className={mcmention.title}>Mentions légales 🖹</h2>
            {/* Start of the "Mention légales" article */}
            <article>
              <p>
                <strong>TJ Peinture SIRET :</strong>
                <span> [{siret || "90250512200017"}]</span>
              </p>
              <p>
                <strong>Siège social :</strong>{" "}
                <span>[{adress || "372 Rue Leon Bourgeois 34500 Bézier"}]</span>
              </p>
              <p>
                <strong>Téléphone : </strong>{" "}
                <span>[{mobile || "0677650306"}]</span>
              </p>
              <p>
                <strong>E-mail : </strong>{" "}
                <span>[{email || " teddy.jeantet26@gmail.com"}]</span>
              </p>
              <p>
                <strong>Numéro de TVA intracommunautaire : </strong>
                <span>[FR65902505122]</span>
              </p>
              <p>
                <strong>Responsable de la publication :</strong>{" "}
                <span>[Mr JEANTET Teddy]</span>
              </p>
              <p>
                <em>
                  Le propriétaire de la société est responsable des mises à jour
                  et des modifications ultérieures du contenu. Le site a été
                  propulsé par Mme VENUAT Coralie.
                </em>
              </p>
              <p>
                <strong>Hébergeur :</strong>{" "}
                <a
                  href="https://www.o2switch.fr/"
                  title="Redirection vers l'hébergeur O2Switch"
                  alt="Redirection vers l'hébergeur O2Switch"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>[O2Switch]</span>
                </a>
              </p>
            </article>
            {/* End of article */}
            {/* Start of the "Cookies" section */}

            <article>
              <h2 className={mcmention.title}>Cookies 🍪</h2>
              <p>
                Notre site web utilise des cookies pour permettre la connexion
                de l'administrateur.
              </p>
              <p>
                Les cookies sont de petits fichiers stockés sur l'appareil de
                l'utilisateur qui contiennent des informations sur la navigation
                sur le site. Les données collectées par les cookies sont
                utilisées uniquement à des <strong>fins de connexion</strong> de
                l'administrateur et
                <strong> ne sont pas partagées avec des tiers</strong>.
              </p>
              <p>
                Les cookies sont stockés sur l'appareil de l'utilisateur pendant
                une durée maximale de <strong>8 heures</strong>. En naviguant
                sur notre site, l'utilisateur consent à l'utilisation de
                cookies.
              </p>
              <p>
                Les données fournies via le formulaire de contact sont utilisées
                uniquement pour la correspondance et ne sont pas stockées. Elles
                ne sont pas utilisées à d'autres fins et ne sont pas partagées
                avec des tiers.
              </p>
            </article>
            {/* End of article */}
          </section>
        </motion.main>
      </AnimatePresence>
    </>
  );
};

export default LegalNotice;
