import React from "react";
import { isValidPassword } from "../../../../../utils/validate-input.utils";
import { useState } from "react";
import { FaRegSadTear } from "react-icons/fa";

const InputPasswordUpdate = ({ value, onChange }) => {
  const [validPassword, setValidPassword] = useState(true);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);

  const handleConfirmPasswordChange = (event) => {
    setPasswordConfirm(event.target.value);
    setPasswordMatch(event.target.value === value);
  };

  const handleBlur = () => {
    if (value) {
      setValidPassword(isValidPassword(value));
    }
  };

  return (
    <>
      <input
        type="password"
        name="password"
        value={value}
        placeholder="Mot de passe"
        onChange={onChange}
        required
        onBlur={handleBlur}
        pattern="^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{7,30}$"
        autoComplete="new-password"
      />
      <input
        type="password"
        name="confirmPassword"
        value={passwordConfirm}
        placeholder="Confirmer le mot de passe"
        onChange={handleConfirmPasswordChange}
        required
      />
      <p><small>(7 caractères min., une minuscule, une majuscule et doit contenir un caractère spécial parmis !@#$%^&*)</small></p>
      {!validPassword && (
        <div>
          <p>
            <FaRegSadTear className="icon-failure" />
          </p>
          <p className="message error-message">
            Le mot de passe ne respecte pas le format requis et ne peut pas être
            vide. (7 caractères min., une minuscule, une majuscule et doit contenir un caractère spécial parmis !@#$%^&*)
          </p>
        </div>
      )}
      {!passwordMatch && (
        <div className="container-failure-message">
          <p>
            <FaRegSadTear className="icon-failure" />
          </p>
          <p className="message error-message">
            Les mots de passe ne correspondent pas.
          </p>
        </div>
      )}
    </>
  );
};

export default InputPasswordUpdate;
