import React, { useState } from "react";
import { isValidEmail } from "../../../../../utils/validate-input.utils";
import { FaRegSadTear } from "react-icons/fa";

const InputEmail = ({ value, onChange }) => {
  const [emailOk, setEmailOk] = useState(true);

  const handleBlur = () => {
    setEmailOk(isValidEmail(value));
  };
  return (
    <>
      <input
        type="email"
        name="email"
        value={value}
        placeholder="Email"
        aria-label="Rentrer l'adresse email souhaitée"
        title="Votre email"
        onChange={onChange}
        required
        onBlur={handleBlur}
        autoComplete="email"
      />
      {!emailOk && (
        <div>
          <p>
            <FaRegSadTear className="icon-failure" />
          </p>
          <p className=" message error-message">
            {" "}
            L'email ne respecte pas le format requis et ne peut pas être vide.
          </p>
        </div>
      )}
    </>
  );
};

export default InputEmail;
