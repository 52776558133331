import React, { useState, useEffect } from "react";
import Logo from "../../header/logo192.png";
import mc from "./login.module.scss";
import Button from "../../tools/buttons/Button.tools";
import { postRequest } from "../../../api/api";
import InputEmail from "../dashboard/items/user-compagny/InputEmail";
import InputPasswordLogin from "../dashboard/items/user-compagny/InputPasswordLogin";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "../../../redux/reducers/user.slice";
import { useSelector } from "react-redux";

const Connection = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoggedIn, isLoggedInChecked } = useSelector((store) => store.user);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { status } = await postRequest(
        "user/login",
        {
          email,
          password,
        },
        true
      );

      if (status === 200) {
        navigate("/dashboard");
        dispatch(setLoggedIn(true));
        setErrorMessage("");
      } else {
        setErrorMessage(
          <p className="message error-message">
            Mot de passe ou adresse email-incorrecte
          </p>
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const content = "Se connecter";

  useEffect(() => {
    if (isLoggedIn) {
      const redirectPage = setTimeout(() => {
        navigate("/dashboard");
      }, 5000);
      return () => {
        clearTimeout(redirectPage);
      };
    }
  }, [isLoggedIn]);
  return (
    <>
      <Helmet>
        <title>Connexion | TJ Peinture</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <AnimatePresence>
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="login"
          className={mc.connection}
          role="main"
        >
          {isLoggedIn && isLoggedInChecked && (
            <section className={mc.section}>
              <p className="message redirection-message">
                Vous êtes déjà connecté. Vous serez rediriger sur votre{" "}
                <strong>tableau de bord </strong>dans 5 secondes ...
              </p>
            </section>
          )}
          
          {!isLoggedIn && isLoggedInChecked && (
            <section className={mc.section}>
              <img src={Logo} alt="Logo du site web TjPeinture" />
              <h1 className={mc.title}>Connexion</h1>

              <form
                className={mc.form}
                onSubmit={handleSubmit}
                aria-label="Formulaire de connexion"
              >
                <InputEmail
                  className={mc.input}
                  value={email}
                  onChange={handleEmail}
                />
                <InputPasswordLogin
                  className={mc.input}
                  value={password}
                  onChange={handlePassword}
                />

                {errorMessage}

                <Button
                  content={content}
                  className={mc.btn}
                  aria-label="Valider pour se connecter"
                />
              </form>
              <NavLink to="/password-recovery" className="password-forgotten">
                Mot de passe oublié ?{" "}
              </NavLink>
            </section>
          )}
        </motion.main>
      </AnimatePresence>
    </>
  );
};

export default Connection;
