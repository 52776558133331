import React from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../../header/Navbar.header";
import nf from "./notfound.module.scss";
import { Helmet } from "react-helmet";
import { AnimatePresence, motion } from "framer-motion";
const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Page introuvable | TJ Peinture</title>
        <meta name="robots" content="noindex, nofollow"/>
      </Helmet>
      <AnimatePresence>
      <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key="not-found"
      className={nf.container}>
        <h1>Erreur 404 - Page non trouvée</h1>
        <p>Désolé, la page que vous recherchez est introuvable.</p>
        <p>
          <NavLink
            to="/"
            className={nf.link}
            title={`Retour à la page d'accueil`}
            alt="Lien pour retourner à la page d'accueil"
          >
            {" "}
            Retourner à la page d'accueil
          </NavLink>
        </p>
      </motion.main></AnimatePresence>
    </>
  );
};

export default NotFound;
