import React, { useEffect, useState } from "react";
import mcdashboard from "./dashboard.module.scss";
import General from "./sections/General.dashboard";
import MyProject from "./sections/MyProject.dashboard";
import MyInformations from "./sections/MyInformations.dashboard";
import MySections from "./sections/MySections.dashboard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

const Dashboard = () => {
  // Status to manage the display of the active section, default value : general
  const [activeNavigation, setActiveNavigation] = useState("general");

  const navigate = useNavigate();
  const { isLoggedIn, isLoggedInChecked } = useSelector((store) => store.user);

  //Functions to manage the change of active section

  const handleGeneral = () => {
    setActiveNavigation("general");
  };
  const handleProjects = () => {
    setActiveNavigation("projects");
  };
  const handleProfil = () => {
    setActiveNavigation("profil");
  };
  const handleSections = () => {
    setActiveNavigation("sections");
  };

  let content;
  // Check if the user is logged in, otherwise display the message and redirect to the home page
  if (!isLoggedIn) {
    content = (
      <p className="message redirection-message">
        Vous devez être connecté pour accéder à cette page. Vous serez rediriger
        à la <strong>page d'accueil </strong>dans 5 secondes ...
      </p>
    );
  } else if (activeNavigation === "general") {
    content = <General />;
  } else if (activeNavigation === "projects") {
    content = <MyProject />;
  } else if (activeNavigation === "profil") {
    content = <MyInformations />;
  } else if (activeNavigation === "sections") {
    content = <MySections />;
  }

  // Use of a useEffect for automatic redirection if the user is not connected after 5 seconds
  useEffect(() => {
    if (!isLoggedIn) {
      const redirectPage = setTimeout(() => {
        navigate("/");
      }, 5000);
      return () => {
        clearTimeout(redirectPage);
      };
    }
  }, [isLoggedIn]);

  return (
    <>
      <Helmet>
        <title>Tableau de bord - Modifiez votre contenu ! | TJ Peinture</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <main
        className={`container ${mcdashboard.dashboard}`}
        id="top"
        role="main"
      >
        {isLoggedIn && isLoggedInChecked && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="dashboard-home"
              className={mcdashboard.title}
            >
              {/* Titles */}
              <h2>Bienvenue sur votre tableau de bord !</h2>
              <h3>
                Commencez dès maintenant à gérer le contenu de votre site web
                qui sera visible pour vos futurs clients.
              </h3>
            </motion.div>
            {/* Navigation bar for the dashboard*/}
            <nav className={mcdashboard["nav-dashboard"]}>
              <ul>
                <li
                  className={`${mcdashboard["navigation-dashboard"]} ${
                    activeNavigation === "general" ? "selected-nav" : ""
                  }`}
                  onClick={handleGeneral}
                >
                  Général
                </li>
                <li
                  className={`${mcdashboard["navigation-dashboard"]} ${
                    activeNavigation === "projects" ? "selected-nav" : ""
                  }`}
                  onClick={handleProjects}
                >
                  Mes projets
                </li>
                <li
                  className={`${mcdashboard["navigation-dashboard"]} ${
                    activeNavigation === "sections" ? "selected-nav" : ""
                  }`}
                  onClick={handleSections}
                >
                  Mes sections
                </li>
                <li
                  className={`${mcdashboard["navigation-dashboard"]} ${
                    activeNavigation === "profil" ? "selected-nav" : ""
                  }`}
                  onClick={handleProfil}
                >
                  Mes informations
                </li>
              </ul>
            </nav>
            {/* Displays content according to the item selected in the navigation bar  */}
            <>{content}</>
          </>
        )}
        {!isLoggedIn && isLoggedInChecked && (
          <section className={mcdashboard.redirection}>{content}</section>
        )}
      </main>
    </>
  );
};

export default Dashboard;
