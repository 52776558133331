import React from "react";

const InputSiret = ({ value, onChange }) => {
  return (
    <>
      <input
        type="number"
        name="siret"
        placeholder="N° SIRET de la société"
        aria-label="N° SIRET de la société TJ Peinture"
        value={value}
        onChange={onChange}
        required
      />
    </>
  );
};

export default InputSiret;
