import React from "react";
import mcdashboard from "../dashboard.module.scss";
import AddPictureBtn from "../../../tools/buttons/AddPictureBtn.tools";
import EditBtn from "../../../tools/buttons/EditBtn.tools";
import DeleteBtn from "../../../tools/buttons/DeleteBtn.tools";
import { AiOutlineLogout } from "react-icons/ai";
import { AnimatePresence, motion } from "framer-motion";
const General = () => {
  return (
    <AnimatePresence>
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key="dashboard"
        className={`${mcdashboard["section-content"]} ${mcdashboard.general}`}
      >
        <article className={mcdashboard["dashboard-presentation"]}>
          <h3 className={mcdashboard["advice-dashboard"]}>
            Tableau de bord pour la gestion de contenu
          </h3>
          <p>
            Notre tableau de bord de gestion de contenu vous permet de gérer
            facilement et efficacement votre site web depuis un emplacement
            centralisé.
          </p>
          <ul className={mcdashboard["legends-navigation"]}>
            <li>
              <p>
                <strong>Mes informations :</strong>
              </p>{" "}
              <p>
                gérer vos informations personnelles telles que votre adresse
                e-mail, mot de passe, ou les informations de ta société.
              </p>
            </li>
            <li>
              <p>
                <strong>Mes sections :</strong>
              </p>{" "}
              <p>
                modifier la description de chaque section de votre site web,
                pour présenter de manière claire et concise votre activité à vos
                visiteurs.
              </p>
            </li>
            <li>
              <p>
                <strong>Mes projets :</strong>
              </p>{" "}
              <p>
                ajouter, supprimer et modifier des projets, ainsi que leurs
                photos associées, qui seront affichés sur ta page "Galeries".
              </p>
            </li>
          </ul>
          <p>
            Utilisez simplement les onglets de navigation pour accéder à chaque
            composant et effectuer les modifications nécessaires.
          </p>{" "}
          <p>
            Notre tableau de bord est conçu pour être intuitif et facile à
            utiliser, même pour les débutants en gestion de site web.
          </p>{" "}
          <p>
            Profitez-en pour personnaliser votre contenu et présenter votre
            activité de manière professionnelle et efficace.
          </p>
        </article>

        <article className={mcdashboard["dashboard-presentation"]}>
          <h3 className={mcdashboard["advice-dashboard"]}>Prise en main : </h3>

          <h4 className={mcdashboard["advice-dashboard"]}>
            Vous venez de vous connecter pour la première fois :{" "}
          </h4>

          <p>
            Un mot de passe vous est fournit pour accéder à votre tableau de
            bord, nous vous conseillons de le{" "}
            <strong className={mcdashboard.important}>
              {" "}
              mettre à jour dès votre première connexion
            </strong>{" "}
            pour des raisons de sécurité.{" "}
          </p>

          <h4 className={mcdashboard["advice-dashboard"]}>
            Légendes des actions disponibles :{" "}
          </h4>

          <ul className={mcdashboard["legends-icons"]}>
            <li>
              <AddPictureBtn />
              <p> Ajouter une ou plusieurs images (1Mo autorisé par image)</p>
            </li>
            <li>
              <EditBtn />
              <p>
                Éditer vos sections, vos informations etc. Par défaut, certaines
                sections ne sont pas supprimables afin de ne pas avoir de champ
                vide.
              </p>
            </li>
            <li>
              <DeleteBtn />
              <p>
                Supprimer un élement. Une confirmation est demandée lors de la
                demande de suppression.
              </p>
            </li>
            <li>
              <AiOutlineLogout className="icons-btn" />
              <p>Se déconnecter</p>
            </li>
          </ul>
          <p>
            En cas de problème avec le serveur, un contenu par défaut est
            affiché afin de ne pas laisser de champ vide et d'avoir l'essentiel
            des informations sur le site web. Il est possible de mettre à jour
            le contenu par défaut en contactant le{" "}
            <a href="mailto:cally.dev@outlook.fr">réalisateur</a> du site
            internet.{" "}
          </p>
        </article>

        <article className={mcdashboard["dashboard-presentation"]}>
          <h3 className={mcdashboard["advice-dashboard"]}>
            Conseils pour redimensionner les images :
          </h3>

          <p>
            <strong>
              Pourquoi une taille maximale de{" "}
              <span className={mcdashboard.important}>1 Mo ?</span>
            </strong>
          </p>
          <ul>
            <li>
              <strong>Rapidité de chargement :</strong> Les images de plus
              grande taille augmentent le temps de chargement du site. Réduire
              la taille du fichier permet des chargements plus rapides,
              améliorant ainsi l'expérience utilisateur.
            </li>
            <li>
              <strong>Utilisation de l'espace de stockage :</strong> Des images
              plus grandes utilisent davantage d'espace sur les serveurs. Garder
              des tailles de fichiers réduites permet d'économiser de l'espace
              de stockage.
            </li>
          </ul>

          <h4 className={mcdashboard["advice-dashboard"]}>
            Outils pour redimensionner les images :
          </h4>

          <p>
            <strong>Sites web :</strong>
          </p>
          <ul>
            <li>
              <strong>
                <a href="https://tinypng.com/" target="_blank" rel="noreferrer">
                  TinyPNG :
                </a>
              </strong>{" "}
              Réduit la taille des fichiers PNG et JPEG tout en conservant une
              bonne qualité d'image.
            </li>
            <li>
              <strong>
                <a
                  href="https://compressor.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Compressor.io :
                </a>
              </strong>{" "}
              Compresse les images JPEG, PNG, SVG et GIF en ligne avec une
              prévisualisation en temps réel.
            </li>
            <li>
              <strong>
                <a
                  href="https://imageoptim.com/fr"
                  target="_blank"
                  rel="noreferrer"
                >
                  ImageOptim :
                </a>
              </strong>{" "}
              Application pour Mac qui optimise les images pour le web.
            </li>
          </ul>
          <p>
            <strong>Logiciels de retouche d'image :</strong>
          </p>
          <ul>
            <li>
              Adobe Photoshop, GIMP, ou Affinity Photo : Permettent de
              redimensionner et de compresser les images.
            </li>
          </ul>

          <h4 className={mcdashboard["advice-dashboard"]}>
            Conseils supplémentaires :
          </h4>

          <ul>
            <li>
              <strong>Redimensionner proportionnellement :</strong> Assurez-vous
              de conserver le rapport hauteur/largeur original pour éviter toute
              déformation de l'image.
            </li>
            <li>
              <strong>Choix du format d'image :</strong> Utilisez des formats
              d'image tels que JPEG pour les photographies, PNG pour les images
              avec transparence, et SVG pour les graphiques vectoriels.
            </li>
          </ul>
          <h4 className={mcdashboard.important}>
            Attention aux droits d'auteur :
          </h4>
          <p>
            Lorsque vous utilisez des images provenant de sources externes,
            assurez-vous de respecter les droits d'auteur. Évitez d'utiliser des
            images protégées sans autorisation ou sans respecter les licences
            d'utilisation. Optez pour des images libres de droits ou obtenez les
            autorisations nécessaires avant de les utiliser sur votre site web.
          </p>
        </article>
      </motion.section>
    </AnimatePresence>
  );
};

export default General;
