import { getRequest, postRequest } from "../api/api";


// Function that checks if the user is connected by making a GET request to the API
// will returns true if the user is connected, otherwise false
export const checkLoggedIn = async () => {
  try {
    const response = await getRequest("user/check-loggedIn", true);
    const { status } = response;
    return status === 200; // l'utilisateur est connecté ou non

} catch (error) {
    console.error(error);
    return false; // l'utilisateur n'est pas connecté
  }
};
// Function that disconnects the user by making a POST request to the API
// will returns true if the user is successfully disconnected, otherwise false
export const logOut = async () => {
  try {
    const response = await postRequest("user/logout", {}, true);
    const { status } = response;
    return status === 200; // l'utilisateur est connecté ou non
  } catch (error) {
    console.error(error);
    console.log("cannot disconnect");
    return false;
  }
};
