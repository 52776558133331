import React from 'react';

const SendBtn = () => {
    return (
        <button type='submit' className='btn-send'> 
            Envoyer
        </button>
    );
};

export default SendBtn;