import React from "react";
import { useState } from "react";

const InputPassword = ({ value, onChange }) => {
  const [existValue, setExistValue] = useState("");
  const handleBlur = () => {
    if (!value) {
      setExistValue(
        <p className=" message error-message">Mot de passe requis</p>
      );
    } else {
      setExistValue("");
    }
  };

  return (
    <>
      <input
        type="password"
        name="password"
        value={value}
        placeholder="Mot de passe"
        aria-label="Votre mot de passe"
        title="Votre mot de passe"
        onChange={onChange}
        required
        onBlur={handleBlur}
        autoComplete="current-password"
      />
      {existValue}
    </>
  );
};

export default InputPassword;
