import React from "react";
import mc from "./login.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import InputEmail from "../dashboard/items/user-compagny/InputEmail";
import { Helmet } from "react-helmet";
import { useState } from "react";
import Button from "../../tools/buttons/Button.tools";
import { postRequest } from "../../../api/api";

const ForgottenPassword = () => {
  const [email, setEmail] = useState("");
  const [isRequestAllowed, setRequestAllowed] = useState(true);
  const [messageInformation, setMessageInformation] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const content = "Envoyer";

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isRequestAllowed) {
      setMessageInformation(
        <p className="message error-message">
          Veuillez patienter avant de faire une autre demande.
        </p>
      );
      return;
    }

    try {
      const { status } = await postRequest(
        "recovery-password",
        {
          email,
        },
        true
      );

      if (status === 200) {
        setMessageInformation(
          <>
            <p className="message success-message">
              Un email de récupération a été envoyé ! Vérifiez votre boîte mail.
            </p>
            <small>Si vous ne voyez pas l'email apparaître, veuillez consulter les spams de votre boîte, sinon contacté le webmaster.</small>
          </>
        );
        setRequestAllowed(false);
        setTimeout(() => {
          setRequestAllowed(true);
        }, 4 * 60 * 1000); // 4 minutes
      } else if (status === 400) {
        setMessageInformation(
          <p className="message error-message">Adresse email incorrecte</p>
        );
      } else {
        setMessageInformation(
          <p className="message error-message">
            Une erreur interne est survenue.
          </p>
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Helmet>
        <title>Connexion : Récupération | TJ Peinture</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <AnimatePresence>
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="login"
          className={mc.connection}
          role="main"
        >
          <section className={mc.section}>
            <h1>Réinitialisation du mot de passe</h1>
            <p>
              Un email sera envoyé à l'adresse connue afin de vous aider à
              accéder à votre compte.
            </p>

            {messageInformation}
            <form className={mc.form} onSubmit={handleSubmit}>
              <InputEmail
                className={mc.input}
                value={email}
                aria-label="Email"
                onChange={handleEmail}
              />
              <Button
                content={content}
                className={mc.btn}
                aria-label="Valider pour se connecter"
              />
            </form>
          </section>
        </motion.main>
      </AnimatePresence>
    </>
  );
};

export default ForgottenPassword;
