import { createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../api/api";

// Action Creator which allows you to retrieve the company's information
export const fetchCompagnyInformations = () => async (dispatch, getState) => {
    try {
        const loading = getState().compagny.loading;
        if (loading) {
            return;
        }
        dispatch(startLoading())
        const {status, result} = await  getRequest('compagny/', true)
        if (status === 200) {
            // Storage of information retrieved in the Redux store thanks to actions
            const adress = result.data.adress
            const mobile = result.data.mobile
            const siret = result.data.siret       
            dispatch(setAdress(adress))
            dispatch(setMobile(mobile))
            dispatch(setSiret(siret))
        }

    } catch (error) {
        console.error({message : "An error occured", error})
    } finally {
        dispatch(stopLoading())
    }
}
export const compagnySlice = createSlice({
    name : "compagny",
    initialState : {
        loading : false, 
        mobile : "",
        adress : "",
        siret : ""
    },
    reducers : {
        startLoading : (state) => {
            return { ...state, loading : true}
        },
        stopLoading : (state) => {
            return { ...state, loading : false}
        },
        //Compagny informations
        //--get
        setMobile : (state, action) => {
            return { ...state, mobile : action.payload}
        }, 
        setAdress : (state, action) => {
            return {...state, adress : action.payload}
        },
        setSiret : (state, action) => {
            return { ...state, siret : action.payload}
        },
        //--update
        updateMobile : (state, action) => {
            return { ...state, mobile : action.payload}
        },
        updateAdress : (state, action) => {
            return { ...state, adress : action.payload}
        },
        updateSiret : (state, action) => {
            return { ...state, siret : action.payload}
        }
        
    }
})

export const {startLoading, stopLoading, setMobile, setAdress, setSiret} = compagnySlice.actions;
export default compagnySlice.reducer;