import { createSlice } from "@reduxjs/toolkit";
import { postFileRequest, deleteRequest, getImageRequest } from "../../api/api";
import { fetchProjectsInformations } from "./projects.slice";

export const fetchPictures = (id) => async (dispatch, getState) => {
  try {
    const loading = getState().picture.loading;

    if (loading) {
      return;
    }
    dispatch(startLoading());

    const { status, result } = await getImageRequest(`pictures/${id}`);
    if (status === 200) {
      dispatch(setPictures(result));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(stopLoading());
  }
};

export const uploadPictures =
  (pictureFiles, projectId, sectionId) => async (dispatch) => {
    try {
      // Ensure that either projectId or sectionId is provided, but not both
      if ((projectId && sectionId) || (!projectId && !sectionId)) {
        throw new Error(
          "Either projectId or sectionId should be provided, but not both"
        );
      }

      let formData = new FormData();
      formData.append("fileImage", pictureFiles);

      // Append the appropriate id to the formData
      if (projectId) {
        formData.append("projectId", projectId);
      } else if (sectionId) {
        formData.append("sectionId", sectionId);
      }

      const response = await postFileRequest("post", formData, true);

      if (response.status === 200) {
        dispatch(addPicture(response.result.data));
      }
      return response;
    } catch (error) {
      console.error(error);
      return { status: 500, error };
    }
  };
export const removePicture = (id) => async (dispatch) => {
  try {
    const { status } = await deleteRequest(`pictures/${id}`, true);

    if (status === 200) {
      dispatch(deletePicture(id));
      dispatch(fetchProjectsInformations());
    }
  } catch (error) {
    console.error(error);
  }
};
export const pictureSlice = createSlice({
  name: "picture",
  initialState: {
    loading: false,
    pictures : []
    
  },
  reducers: {
    startLoading: (state) => {
      return { ...state, loading: true };
    },
    stopLoading: (state) => {
      return { ...state, loading: false };
    },
    setPictures: (state, action) => {
      return { ...state, pictures: action.payload };
    },
    addPicture: (state, action) => {
      return { ...state, pictures: action.payload };
    },

    deletePicture: (state, action) => {
      const updatedPictures = [...state.pictures];
      updatedPictures.filter((picture) => picture.id !== action.payload);
      return { ...state, pictures: updatedPictures };
    },
  },
});

export const {
  startLoading,
  stopLoading,
  setPictures,
  addPicture,
  deletePicture,

} = pictureSlice.actions;
export default pictureSlice.reducer;
