import React from "react";

const InputProjectTitle = ({ value, onChange }) => {
  return (
    <input
      type="text"
      value={value}
      onChange={onChange}
      name="title"
      placeholder="Nom du projet"
    />
  );
};

export default InputProjectTitle;
