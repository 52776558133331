import React from "react";

const InputMobile = ({ value, onChange }) => {
  return (
    <>
      <input
        type="tel"
        name="tel"
        id="tel"
        placeholder="N° de téléphone"
        aria-label="Rentrer le numéro de téléphone"
        title="Votre numéro de téléphone à dix chiffres"
        value={value}
        onChange={onChange}
        required
        autoComplete="tel-national"
      />
    </>
  );
};

export default InputMobile;
