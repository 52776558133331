import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewProject,
  fetchProjectsInformations,
  removeProject,
} from "../../../../redux/reducers/projects.slice";
import { deleteRequest, getImageRequest } from "../../../../api/api";
import DeleteBtn from "../../../tools/buttons/DeleteBtn.tools";
import EditBtn from "../../../tools/buttons/EditBtn.tools";
import { openModal, closeModal } from "../../../../redux/reducers/modals.slice";
import Modal from "../../../tools/Modal.tools";
import { putRequest } from "../../../../api/api";
import SendBtn from "../../../tools/buttons/SendBtn";
import InputProjectTitle from "../items/projects-pictures/InputProjectTitle";
import InputProjectContent from "../items/projects-pictures/InputProjectContent";
import InputProjectDate from "../items/projects-pictures/InputProjectDate";
import {
  manageFormatDate,
  manageFormatDateFront,
} from "../../../../utils/date.utils";
import AddPictureBtn from "../../../tools/buttons/AddPictureBtn.tools";
import { uploadPictures } from "../../../../redux/reducers/pictures.slice";
import mcdashboard from "../dashboard.module.scss";
import { isImageFile } from "../../../../utils/validate-input.utils";
import { setCurrentPage } from "../../../../redux/reducers/projects.slice";
import { motion, AnimatePresence } from "framer-motion";

const MyProject = () => {
  const [updateMessage, setUpdateMessage] = useState(null);
  const [updateMessageImage, setUpdateMessageImage] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const dispatch = useDispatch();
  const [newTitleProject, setNewTitleProject] = useState("");
  const [newContentProject, setNewContentProject] = useState("");
  const [newDateProject, setNewDateProject] = useState("");
  const [id, setId] = useState(null);
  //State redux
  //const { projects, loading } = useSelector((store) => store.project);

  const isAddProjectModal = useSelector((state) => state.modals.addProject);
  const isAddPictureModal = useSelector((state) => state.modals.addPicture);
  const isEditProjectModal = useSelector((state) => state.modals.editProject);
  const isDeleteModalProject = useSelector(
    (state) => state.modals.deleteModalProject
  );
  const isDeleteModalPicture = useSelector(
    (state) => state.modals.deleteModalPicture
  );
  const isPreviewModalPicture = useSelector(
    (state) => state.modals.imagePreviewModal
  );
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const [image, setImage] = useState("");
  const [pictureUpdated, setPictureUpdated] = useState("");

  const { projects, loading, currentPage, totalPages } = useSelector(
    (store) => store.project
  );

  // ...

  const handlePageChange = (newPage) => {
    dispatch(setCurrentPage(newPage));
    dispatch(fetchProjectsInformations(newPage));
  };
  ////////////////////////////////////////////////////////////////
  /// Manage project
  const updateProject = async (id, updateField) => {
    try {
      const response = await putRequest(`projects/${id}`, updateField, true);

      if (response.status === 200) {
        setUpdateMessage(
          "Les informations du projet ont été mis à jour avec succès"
        );
        setStatusMessage("success-message");
        dispatch(fetchProjectsInformations())
        handleCloseModal("editProject");
      } else {
        handleCloseModal("editProject");
        setUpdateMessage("Les informations n'ont pas pu être mis à jour");
        setStatusMessage("error-message");
      }
    } catch (error) {
      console.error(error);
    }
  };

  //-Modals for projects
  const handleOpenModalAddProject = (modalToOpen) => {
    dispatch(openModal(modalToOpen));
  };

  const handleOpenModalEditProject = (modalToOpen, project) => {
    setId(project.id);
    setNewTitleProject(project.title);
    setNewContentProject(project.content);
    const date = manageFormatDate(project.created_at);
    setNewDateProject(date);
    dispatch(openModal(modalToOpen));
  };

  const handleEditProjectSubmit = (e) => {
    e.preventDefault();
    updateProject(id, {
      title: newTitleProject,
      content: newContentProject,
      created_at: newDateProject || null,
    });
  };

  const handleAddProjectSubmit = async (e) => {
    e.preventDefault();
    //Check if the fields isn't empty
    if (!newTitleProject || !newContentProject || !newDateProject) {
      setUpdateMessage("Le champ ne doit pas rester vide");
      setStatusMessage("error-message");

      return;
    }

    await dispatch(
      createNewProject(newTitleProject, newContentProject, newDateProject)
    );
    dispatch(fetchProjectsInformations());

    setUpdateMessage(`Projet ${newTitleProject} ajouté avec succès ! `);
    setStatusMessage("success-message");

    handleCloseModal("addProject");
  };

  const handleDeleteProject = async (projectId) => {
    await dispatch(removeProject(projectId));
    dispatch(fetchProjectsInformations());
    setUpdateMessage("Le projet a été supprimé avec succès");
    setStatusMessage("success-message");
  };
  ////////////////////////////////////////////////////////////////
  ///Manage image
  const handleSubmitAddPicture = async (e) => {
    e.preventDefault();
    const fileSizeLimitMb = 1000000; // 1Mo will be accepted

    for (let i = 0; i < image.length; i++) {
      if (image[i].size > fileSizeLimitMb) {
        setUpdateMessageImage("L'image est trop lourde, 1Mo autorisé");
        setStatusMessage("error-message");
        setTimeout(() => {
          setUpdateMessage("");
          setUpdateMessageImage("");
          setStatusMessage("");
        }, 10000);
        return;
      }
    }
    //Check if the file isn't empty, if empty : prevents the form from being sent or verify the mimetype;

    if (!image || !isImageFile(image)) {
      setStatusMessage("error-message");
      setUpdateMessageImage(
        "Veuillez sélectionner un fichier d'image valide !"
      );
      setUpdateMessage("L'image n'a pas pu être envoyé !");
      setTimeout(() => {
        setUpdateMessage("");
        setUpdateMessageImage("");
        setStatusMessage("");
      }, 10000);

      return;
    }

    try {
      for (let i = 0; i < image.length; i++) {
        const response = await dispatch(uploadPictures(image[i], id, null));

        if (response.status === 400 || response.status === 500) {
          setUpdateMessage(
            "Erreur lors de l'envoi de l'image ! Vérifiez le type de fichier, la taille ou le nom de l'image."
          );
          setUpdateMessageImage(
            "Erreur lors de l'envoi de l'image ! Vérifiez le type de fichier, la taille ou le nom de l'image."
          );
          setStatusMessage("error-message");
          setImage("");
          setTimeout(() => {
            setUpdateMessage("");
            setUpdateMessageImage("");
            setStatusMessage("");
          }, 10000);
        } else {
          dispatch(fetchProjectsInformations());
          setUpdateMessage("Image envoyée avec succès !");
          setUpdateMessageImage("");
          setStatusMessage("success-message");
          setImage("");
          setTimeout(() => {
            setUpdateMessage("");
            setUpdateMessageImage("");
            setStatusMessage("");
          }, 10000);
        }
      }
      handleCloseModal("addPicture");
    } catch (error) {
      console.error(error);
      setUpdateMessage("Une erreur est survenue lors de l'envoi de l'image.");
      setStatusMessage("error-message");
    }
  };

  //It retrieves the selected file and stores it in the image state.
  const handleImageChange = (e) => {
    setImage(Array.from(e.target.files));
  };
  const removePicture = async (pictureId) => {
    try {
      //Send a delete request to the server if the picture exist
      const response = await deleteRequest(`pictures/${pictureId}`, true);
      //
      if (response.status === 200) {
        setUpdateMessage("L'image a bien été supprimée");
        setStatusMessage("success-message");
        setPictureUpdated(!pictureUpdated);
      } else {
        setUpdateMessage("Impossible de supprimer l'image'");
        setStatusMessage("error-message");
      }
    } catch (error) {
      console.error(error);
    }
  };
  //Will be call on click in the DeleteBtn, this function call removePicture
  const handleDeletePicture = async (id) => {
    await removePicture(id);
  };

  ////////////////////////////////////////////////////////////////
  ///Modals manage & details
  const handleOpenModalUploadPicture = (modalToOpen, project) => {
    dispatch(openModal(modalToOpen));
    setId(project.id);
  };

  const handleCloseModal = (modalToClose) => {
    dispatch(closeModal(modalToClose));
  };

  const handleOpenModalDeleteProject = (modalToOpen, projectId) => {
    setId(projectId);
    dispatch(openModal(modalToOpen));
  };
  const handleOpenModalDeletePicture = (modalToOpen, pictureId) => {
    setId(pictureId);
    dispatch(openModal(modalToOpen));
  };

  const handleOpenPreviewPicture = async (pictureId) => {
    try {
      const response = await getImageRequest(pictureId, true);
      if (response.status === 201) {
        const imageURL = response.result;
        setSelectedImageURL(imageURL);
        dispatch(openModal("imagePreviewModal"));
      } else {
        throw new Error("Error loading image");
      }
    } catch (error) {
      console.error(error, "Error loading image");
    }
  };
  const handleCloseModalDeleteProject = (modalToClose) => {
    dispatch(closeModal(modalToClose));
    setId(null);
  };
  const handleCloseModalDeletePicture = (modalToClose) => {
    dispatch(closeModal(modalToClose));
    setId(null);
  };
  const handleConfirmDeleteProject = async () => {
    if (id) {
      await handleDeleteProject(id);
      handleCloseModalDeleteProject("deleteModalProject");
    }
  };
  const handleConfirmDeletePicture = async () => {
    if (id) {
      await handleDeletePicture(id);
      handleCloseModalDeletePicture("deleteModalPicture");
    }
  };
  const modalsContent = {
    addProject: (
      <form onSubmit={handleAddProjectSubmit}>
        <InputProjectTitle
          value={newTitleProject}
          onChange={(e) => setNewTitleProject(e.target.value)}
        />
        <InputProjectContent
          value={newContentProject}
          onChange={(e) => setNewContentProject(e.target.value)}
        />
        <InputProjectDate
          value={newDateProject}
          onChange={(e) => setNewDateProject(e.target.value)}
        />
        <SendBtn />
      </form>
    ),
    editProject: (
      <form onSubmit={handleEditProjectSubmit}>
        <InputProjectTitle
          value={newTitleProject}
          onChange={(e) => setNewTitleProject(e.target.value)}
        />
        <InputProjectContent
          value={newContentProject}
          onChange={(e) => setNewContentProject(e.target.value)}
        />
        <InputProjectDate
          value={newDateProject}
          onChange={(e) => setNewDateProject(e.target.value)}
        />

        <SendBtn />
      </form>
    ),

    addPicture: (
      <form onSubmit={handleSubmitAddPicture}>
        <input
          type="file"
          name="file"
          accept="image/*"
          multiple
          onChange={handleImageChange}
        />
        <p className={`message ${statusMessage}`}>{updateMessageImage}</p>
        <SendBtn />
      </form>
    ),
    deleteModalProject: (
      <>
        <p>Êtes-vous sûr de vouloir supprimer ce projet ?</p>
        <button className="btn-delete" onClick={handleConfirmDeleteProject}>
          Supprimer
        </button>
      </>
    ),
    deleteModalPicture: (
      <>
        <p>Êtes-vous sûr de vouloir supprimer cette image ?</p>
        <button className="btn-delete" onClick={handleConfirmDeletePicture}>
          Supprimer
        </button>
      </>
    ),
    previewModalPicture: (
      <>
        {/* Image */}
        <img src={selectedImageURL} alt={`aperçu de l'image`} />
      </>
    ),
  };

  /////////////////////////
  const modalsTitle = {
    addProject: "Ajouter un projet",
    addPicture: "Ajouter des photos",
    editProject: "Modifier les informations du projet",
    deleteModal: "Supprimer l'élement",
    previewPicture: "Aperçu de la photo",
  };

  useEffect(
    () => {
      dispatch(fetchProjectsInformations());
    },
    [pictureUpdated],
    [dispatch]
  );

  return (
    <section className={mcdashboard["section-content"]}>
      <button
        onClick={() => handleOpenModalAddProject("addProject")}
        className="add-project"
      >
        Ajouter un nouveau projet
      </button>
      <p className={`message ${statusMessage}`}>{updateMessage}</p>

      {/* Start of the table, the table displays the list of the user's projects, the name of the photos they contain. */}
      <table className={mcdashboard["table-projects"]}>
        <caption>Mes réalisations </caption>
        <tbody>
          {loading ? (
            <tr>
              <th>Chargement...</th>
            </tr>
          ) : (
            <>
              <AnimatePresence>
                {projects?.map((project) => (
                  <motion.tr
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key={project.id}
                    id={project.id}
                  >
                    <th>{project.title}</th>
                    <td data-label="Description">{project.content}</td>
                    <td data-label="Date de réalisation">
                      {manageFormatDateFront(project.created_at)}
                    </td>
                    <td data-label="Images">
                      {project.Pictures.length > 0 ? (
                        <ul className={mcdashboard["list-pictures"]}>
                          {project.Pictures.map((picture) => (
                            <li key={picture.file_name}>
                              <p
                                className="preview-picture"
                                onClick={() =>
                                  handleOpenPreviewPicture(picture.id)
                                }
                              >
                                {picture.file_name}
                              </p>

                              <p>
                                <DeleteBtn
                                  onClick={() =>
                                    handleOpenModalDeletePicture(
                                      "deleteModalPicture",
                                      picture.id
                                    )
                                  }
                                />
                              </p>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <>
                          <p className="preview-picture">
                            Aucune image disponible pour ce projet
                          </p>
                          <AddPictureBtn
                            onClick={() =>
                              handleOpenModalUploadPicture(
                                "addPicture",
                                project
                              )
                            }
                          />
                        </>
                      )}
                    </td>
                    <td data-label="Actions">
                      <EditBtn
                        onClick={() =>
                          handleOpenModalEditProject("editProject", project)
                        }
                      />

                      <DeleteBtn
                        onClick={() =>
                          handleOpenModalDeleteProject(
                            "deleteModalProject",
                            project.id
                          )
                        }
                      />
                      <AddPictureBtn
                        onClick={() =>
                          handleOpenModalUploadPicture("addPicture", project)
                        }
                      />
                    </td>
                  </motion.tr>
                ))}
              </AnimatePresence>
            </>
          )}
        </tbody>
      </table>
      {/* End of table */}
      {/* Pagination */}
      <div className="pagination-container">
        <span className="pagination-info">{`Page ${currentPage} sur ${totalPages}`}</span>
        <div className="pagination-buttons-container">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Précédent
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Suivant
          </button>
        </div>
      </div>

      {/* //////////////////////////////////////////////////////////////// */}
      {/* Will open here each of modals onClick */}
      {/* Modals for project */}
      {isAddProjectModal && (
        <section className="modal-container">
          <Modal
            title={modalsTitle.addProject}
            content={modalsContent.addProject}
            onClose={() => handleCloseModal("addProject")}
          />
        </section>
      )}
      {isEditProjectModal && (
        <section className="modal-container">
          <Modal
            title={modalsTitle.editProject}
            content={modalsContent.editProject}
            onClose={() => handleCloseModal("editProject")}
          />
        </section>
      )}
      {isDeleteModalProject && (
        <section className="modal-container">
          <Modal
            title={modalsTitle.deleteModal}
            content={modalsContent.deleteModalProject}
            onClose={() => handleCloseModal("deleteModalProject")}
          />
        </section>
      )}

      {/* Modals for picture */}
      {isAddPictureModal && (
        <section className="modal-container">
          <Modal
            title={modalsTitle.addPicture}
            content={modalsContent.addPicture}
            onClose={() => handleCloseModal("addPicture")}
          />
        </section>
      )}
      {isDeleteModalPicture && (
        <section className="modal-container">
          <Modal
            title={modalsTitle.deleteModal}
            content={modalsContent.deleteModalPicture}
            onClose={() => handleCloseModal("deleteModalPicture")}
          />
        </section>
      )}
      {isPreviewModalPicture && (
        <section className="modal-container">
          <Modal
            title={modalsTitle.previewPicture}
            content={modalsContent.previewModalPicture}
            onClose={() => handleCloseModal("imagePreviewModal")}
          />
        </section>
      )}
    </section>
  );
};

export default MyProject;
