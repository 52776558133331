import { URL_API } from "../constants/api-url.constants";


//Crud request

const getRequest = async (url, auth = false) => {
  const config = {
    method: "GET",
    headers: { "Content-type": "application/json; charset=UTF-8" },
  };

  if (auth) {
    config.credentials = "include";
  }

  return await request(url, config);
};

const getImageRequest = async (id, auth = false) => {
  const config = {
    method: "GET",
  };

  if (auth) {
    config.credentials = "include";
  }
  
  return await requestImage(id, config);
};

const postRequest = async (url, body = {}, auth = false) => {
  const config = {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-type": "application/json; charset=UTF-8" },
  };

  if (auth) {
    config.credentials = "include";
  }

  return await request(url, config);
};

const postFileRequest = async (url, formData = {},auth = false) => {
  const config = {
    method: "POST",
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // },
    body: formData,
  };

  if (auth) {
    config.credentials = "include";
  }

  return await requestImage(url, config);
};

const putRequest = async (url, body = {}, auth = false) => {
  const config = {
    method: "PUT",
    body: JSON.stringify(body),
    headers: { "Content-type": "application/json; charset=UTF-8" },
  };
  if (auth) {
    config.credentials = "include";
  }

  return await request(url, config);
};

const deleteRequest = async (url, auth = false) => {
  const config = {
    method: "DELETE",
    headers: { "Content-type": "application/json; charset=UTF-8" },
  };

  if (auth) {
    config.credentials = "include";
  }
  return await request(url, config);
};

const request = async (url, config) => {
  let status = -1;
  let error = null;
  let result = null;

  try {
    const response = await fetch(`${URL_API}${url}`, config);
    status = response.status;
    result = await response.json();
  } catch (error) {
    error = error.message;
  } finally {
    return handleResponse(result, status, error);
  }
};

const requestImage = async (id, config) => {
  let status = -1;
  let error = null;
  let result = null;

  try {
    const response = await fetch(`${URL_API}pictures/${id}`, config);

    status = response.status;
    result = await response.blob();
    const imageObjectUrl = URL.createObjectURL(result);
    result = imageObjectUrl || null;
  } catch (error) {
    console.error(error)
  } finally{
    return handleResponse(result, status, error)
  }
}

const handleResponse = (result, status, error) => {
  const hasError = !result || status >= 400;
  return {
    status,
    result: hasError ? null : result,
    error: hasError ? `result is null ${error || ""}` : null,
  };
};

export { getRequest, postRequest, deleteRequest, putRequest, postFileRequest, getImageRequest };