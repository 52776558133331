import React from "react";
import CloseBtn from "./buttons/CloseBtn.tools";
import { AnimatePresence, motion } from "framer-motion";

// title: a string representing the title of the Modal
// content: a string representing the content of the Modal
// onClose : a callback function that will be called when the user closes the Modal

const Modal = ({ title, content, onClose }) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key="modal"
        className="modal"
      >
        <h2>{title}</h2>
        <article>{content}</article>
        <CloseBtn click={onClose} />
      </motion.div>
    </AnimatePresence>
  );
};

export default Modal;
