import { createSlice } from "@reduxjs/toolkit";
import { getRequest, putRequest } from "../../api/api";
import { useSelector } from "react-redux";

// Action Creator which allows you to retrieve the user's information
export const fetchUserInformations = () => async (dispatch, getState) => {
  try {
    const loading = getState().user.loading;
    if (loading) {
      return;
    }
    dispatch(startLoading());
    const { status, result, error } = await getRequest("user/");

    if (status === 200) {
      const email = result.user.email;
      const id = result.user.id;
      // Storage of information retrieved in the Redux store thanks to actions

      dispatch(setEmail(email));
      dispatch(setId(id));
    } else {
      console.error(error);
    }
  } catch (error) {
    console.error({ message: "An error occured" });
  } finally {
    dispatch(stopLoading());
  }
};

// Action Creator which allows to update the information of the user
export const updateUserInformation =
  (updateField) => async (dispatch, getState) => {
    try {
      const loading = getState().user.loading;
      const id = useSelector((state) => state.user.id);
      
      if (loading) {
        return;
      }
      const { status, error } = await putRequest(
        `user/${id}`,
        updateField
      );
      if (status === 200) {
        //storage the new value
        dispatch(setEmail(updateField));
      } else {
        console.error({ message: "error", error });
      }
    } catch (error) {
      console.error({ message: "An error occured during update" });
    }
  };
export const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    email: "",
    password: "",
    id: "",
    isLoggedIn: false,
    isLoggedInChecked: false, // boolean to check if the request has already been sent
  },
  reducers: {
    startLoading: (state) => {
      return { ...state, loading: true };
    },
    stopLoading: (state) => {
      return { ...state, loading: false };
    },
    //User informations
    setEmail: (state, action) => {
      return { ...state, email: action.payload };
    },
    setUpdateEmail: (state, action) => {
      return { ...state, email: action.payload };
    },
    setId: (state, action) => {
      return { ...state, id: action.payload };
    },
    updatePassword: (state, action) => {
      return { ...state, password: action.payload };
    },
    setLoggedIn: (state, action) => {
      return { ...state, isLoggedIn: action.payload };
    },
    loggedInChecked: (state,) => {
        return { ...state, isLoggedInChecked: true };
    },
      
  },
});

export const {
  startLoading,
  stopLoading,
  setEmail,
  setId,
  setUpdateEmail,
  updatePassword,
  setLoggedIn,
  loggedInChecked
} = userSlice.actions;
export default userSlice.reducer;
