import React, { useEffect } from "react";
import mchome from "./home.module.scss";
import Slide from "./items/Slider.home";
import Button from "../../tools/buttons/Button.tools";
import rollPain from "../../../img/roll-paint-1.svg";
import blop from "../../../img/blop2.svg";
import atelierMorgans from "../../../img/atelier-morgans.jpg";
import Maps from "./items/Maps.home";
import { useDispatch, useSelector } from "react-redux";
import { fetchSectionsInformations } from "../../../redux/reducers/sections.slice";
import { useNavigate } from "react-router-dom";
import { fetchCompagnyInformations } from "../../../redux/reducers/compagny.slice";
import { Helmet } from "react-helmet";
import Logo from "../../../img/logo192.png";
import { AnimatePresence, motion } from "framer-motion";
const App = () => {
  const contentBtnProject = "Mes projets >";
  const contentContact = "Contactez-moi";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sections, loading } = useSelector((store) => store.section);
  const { mobile, adress } = useSelector((store) => store.compagny);

  useEffect(() => {
    dispatch(fetchSectionsInformations());
  }, []);
  useEffect(() => {
    dispatch(fetchCompagnyInformations());
  }, []);
  return (
    <>
      <Helmet>
        <title>
          TJ Peinture - Artisan Peintre Professionnel | Services de Peinture
        </title>
        <meta
          name="description"
          content="TJ Peinture - Artisan peintre professionnel à Béziers proposant différents services : Peinture Intérieure - Peinture extérieure - Peinture Bâtiment - Papier peint - Revêtement sol - Rénovation - Décoration."
        />
        <meta
          name="keywords"
          content="TJ Peinture, peinture, devis gratuits, auto entrepreneur, rénovation, peinture intérieure, peinture extérieure, revêtement sol"
        />
        {/* Meta robots */}
        <meta name="robots" content="index, follow" />

        {/* Open Graph balises for social networks */}

        <meta property="og:image" content={Logo} />
        <meta
          property="og:description"
          content="Artisan peintre spécialisé à Béziers | Peinture Intérieure - Peinture extérieure - Peinture Bâtiment - Rénovation."
        />
        <meta property="og:locale" content="fr_FR" />
        <meta
          property="og:title"
          content="TJ Peinture - Artisan Peintre à Béziers"
        />
      </Helmet>

      <AnimatePresence>
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="home"
          className={` ${mchome.home} container`}
          id="top"
          role="main"
        >
          {/* Titles  */}
          <div className={mchome["title"]}>
            <h1>TJ Peinture</h1>
            <h2>Artisan peintre</h2>
            {/* Summary for quick access to content  */}
            <nav
              className={mchome["rapid-access-nav"]}
              aria-label="Menu secondaire pour se déplacer plus rapidement sur la page"
            >
              <ul className={mchome.summary}>
                <li>
                  <a
                    href="#identity"
                    aria-label="Accéder à la section : Qui suis-je ? "
                  >
                    Qui suis-je ? /
                  </a>
                </li>
                <li>
                  <a
                    href="#missions"
                    aria-label="Accéder à la section : Mes missions "
                  >
                    Mes missions /
                  </a>
                </li>
                <li>
                  <a
                    href="#disponibility"
                    aria-label="Accéder à la section : Mes disponibilités"
                  >
                    Mes disponibilités /
                  </a>
                </li>
                <li>
                  <a
                    href="#contact"
                    aria-label="Accéder à la section : Me contacter "
                  >
                    Me contacter
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          {/* Start of the section "Qui somme-nous" */}
          <section className={`${mchome.presentation} container`}>
            {loading ? (
              <div className="loader" />
            ) : sections.length === 0 ? (
              //If section is empty return a default content for the section
              <article>
                <h3 id="identity" className="highlighted-text">
                  Qui suis-je ?
                </h3>
                <p>
                  Forgé de 5 ans d'expérience en tant que salarié, je travaille
                  désormais en auto entreprise depuis 2021. Mon objectif est de
                  proposer des services de peinture professionnels et de qualité
                  pour les particuliers et les professionnels. Passionné par mon
                  métier, je mets tout en œuvre pour satisfaire les besoins de
                  mes clients. Si vous cherchez un artisan peintre indépendant
                  de confiance pour vos projets de peinture, n'hésitez pas à me
                  contacter. Je suis disponible pour répondre à toutes vos
                  questions et pour vous accompagner dans la réalisation de vos
                  projets.
                </p>
              </article>
            ) : (
              <article>
                <h3 id="identity" className="highlighted-text">
                  {sections[0]?.title}
                </h3>
                <p>{sections[0]?.content}</p>
              </article>
            )}
          </section>
          {/* End of section */}

          {/* Start of the section with a little slider of TjPeinture's realization */}
          <section className={`${mchome.presentation} container`}>
            <Slide />
            <Button
              className={mchome.btn}
              content={contentBtnProject}
              onClick={() => navigate("/realisations")}
              aria-label="Redirige vers la page Galeries"
              title="Aller à la page Galeries"
            />
          </section>
          {/* End of section */}

          {/* Start of the section "Mes missions" */}
          <section className={`${mchome.presentation} container`}>
            {loading ? (
              <div className="loader"></div>
            ) : sections.length === 0 ? (
              //If section is empty return a default content for the section
              <article>
                <h3 id="missions" className="highlighted-text">
                  Mes missions :
                </h3>
                <p>
                  Je suis un artisan peintre en bâtiment et je me spécialise
                  dans la mise en peinture de différentes surfaces d'un
                  bâtiment. Mes missions principales consistent à préparer les
                  supports à peindre, à appliquer des revêtements décoratifs ou
                  protecteurs, à réaliser des travaux de rénovation et
                  d'entretien, et à assurer la finition des surfaces peintes. En
                  tant qu'artisan peintre en bâtiment, je suis capable de
                  travailler sur différents types de supports, tels que les
                  murs, les plafonds, les sols, les portes, les fenêtres, les
                  volets, les escaliers, etc. Je suis également en mesure de
                  maîtriser différentes techniques de peinture, telles que
                  l'application au rouleau, au pinceau, à la brosse, au
                  pistolet, ou encore la projection mécanique.
                </p>
              </article>
            ) : (
              <article>
                <h3 id="missions" className="highlighted-text">
                  {sections[1]?.title}
                </h3>
                <p>{sections[1]?.content}</p>
              </article>
            )}
          </section>
          {/* End of section */}

          {/* Start of the section "Hours" */}
          <section className={` ${mchome.svg} `}>
            <img className={mchome.roll} src={rollPain} alt="" loading="lazy" />

            <img
              className={mchome.blop}
              src={blop}
              alt="tâche"
              loading="lazy"
            />
            <article className={mchome.hours}>
              <h3 id="disponibility">Horaires :</h3>
              {loading ? (
                <div className="loader" />
              ) : sections.length === 0 ? (
                //If section is empty return a default content for the section
                <p>Du lundi au dimanche</p>
              ) : (
                <p>{sections[2]?.content}</p>
              )}
              <p>-</p>
              {loading ? (
                <div className="loader" />
              ) : sections.length === 0 ? (
                //If section is empty return a default content for the section
                <p>De 08h00 à 20h00</p>
              ) : (
                <p>{sections[3]?.content}</p>
              )}
            </article>
          </section>
          {/* End of section */}

          {/* Start of section "contact" */}
          <section
            id="contact"
            className={`${mchome.presentation} ${mchome.contact}  `}
          >
            <p>Me contacter :</p>
            {loading ? (
              <div className="loader" />
            ) : sections.length === 0 ? (
              //If section is empty return a default content for the section
              <a href="tel:0677650306" aria-label="Appeler TJ Peinture">
                06.77.65.03.06
              </a>
            ) : (
              <a href="tel:0677650306" aria-label="Appeler TJ Peinture">
                {mobile}
              </a>
            )}
          </section>
          <Maps adress={adress || "372 Rue Leon Bourgeois 34500 Bézier"} />
          <section className={`${mchome.presentation} ${mchome.quote}  `}>
            <p>Besoin d'un devis ou de renseignements ? </p>
            <Button
              onClick={() => navigate("/contact")}
              className={mchome.btn}
              content={contentContact}
              title="Redirection vers la page : Contact"
              alt="Redirection vers la page : Contact"
            />
            <a
              href="https://atelier-morgans.fr/"
              className={mchome["logo-atelier-morgans"]}
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                src={atelierMorgans}
                alt="Lien vers le site web de l'entreprise Atelier Morgans"
                title="Lien vers le site web de l'entreprise Atelier Morgans"
                loading="lazy"
              />
            </a>
            <p>
              En manque d'inspiration ? L'
              <a
                href="https://atelier-morgans.fr/"
                title="Site web Atelier Morgans"
                target="_blank"
                className={mchome.advertising}
                rel="noreferrer"
              >
                Atelier MORGANS
              </a>{" "}
              vous propose des décorations murales sur mesure, adaptées à vos
              envies !
            </p>
          </section>

          {/*End of section*/}
        </motion.main>
      </AnimatePresence>
    </>
  );
};

export default App;
