/**
 * Permet de formater une date
 * @param {string} dataString
 * @return la date formatée sous chaîne de caractère correspond à 'année/mois/jour'
 */

import format from "date-fns/format";

export const manageFormatDate = (dataString) => {
  const date = new Date(dataString);
  const formattedDate = format(date, "yyyy-MM-dd");
  return formattedDate;
};

export const manageFormatDateFront = (dataString) => {
    const date = new Date(dataString);
    const formattedDate = format(date, "dd-MM-yyyy");
    return formattedDate;
  };

export const sliceDate = (dataString) => {
  const date = dataString.slice(0, 10);
  return date;
};
