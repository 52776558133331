import React, { useState } from "react";
import blue from "../../../../img/blue-print.jpg";
import deco from "../../../../img/deco.jpg";
import facade from "../../../../img/facade.jpg";
import "./carousel.scss";
import { Carousel } from "react-responsive-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getImageRequest } from "../../../../api/api";

const Slide = () => {
  const dispatch = useDispatch();
  const { sections, loading } = useSelector((store) => store.section);
  const [imageMapping, setImageMapping] = useState({});



  useEffect(() => {
    const getPicturesSections = async () => {
      const newImageMapping = {};
      if (sections && sections[0] && sections[0].Pictures) {
        for (let i = 0; i < sections[0].Pictures.length; i++) {
          const picture = sections[0].Pictures[i];
          const image = (await getImageRequest(picture.id)).result;
          newImageMapping[picture.id] = image;
        }
      }
      setImageMapping(newImageMapping);
    };
    getPicturesSections();
  }, [sections]);
  
  return (
    <article className="container carousel-home">
      {loading ? (
        <div className="loader" />
      ) : (
        <>
          {sections[0] && sections[0].Pictures && sections[0].Pictures.length > 0 ? (
            <Carousel
              showStatus={false}
              autoPlay={true}
              showArrows={false}
              infiniteLoop={true}
              stopOnHover={true}
              interval={6000}
              emulateTouch={true}
              transitionTime={350}
            >
              {sections[0].Pictures.map((picture) => (
                <div key={picture.id}>
                  
                  <img
                    src={imageMapping[picture.id]}
                    alt="Travaux de peintures réalisés par TJ Peinture "
                    title="Travaux réalisés par TJ Peinture - Peinture intérieure, extérieure, façade, Béziers"
                  />
                </div>
              ))}
            </Carousel>
          ) : (
            <Carousel
              showStatus={false}
              autoPlay={true}
              showArrows={false}
              infiniteLoop={true}
              stopOnHover={true}
              interval={6000}
              emulateTouch={true}
              transitionTime={350}
            >
              <div>
                <img src={blue} alt="Peinture intérieure bleue par TJ Peinture - Béziers" title="Peinture murale par TjPeinture" />
              </div>
              <div>
                <img
                  src={deco}
                  alt="Peinture intérieure blanche - Béziers "
                  title="Peinture par Tj Peinture - Béziers"
                />
              </div>
              <div>
                <img
                  src={facade}
                  alt="Facade extérieur de la boutique Play Skateshop, réalisée par la société Tj Peinture - Béziers"
                  title="Facade extérieur noire de la boutique Play SkateShop, réalisée par Tj Peinture - Béziers"
                />
              </div>
            </Carousel>
          )}
        </>
      )}
    </article>
  );
};

export default Slide;
