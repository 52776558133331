import { regPassword, regEmail } from "../constants/regex.constants";

// Function that checks if the email address is valid using a regular expression
// @param email : the email address to check as a string
// @returns true if the email address is valid, otherwise false
export const isValidEmail = (email) => {
  return regEmail.test(email.trim());
};

// Function that checks if the password is valid using a regular expression
// @param password : the password to check as a string
// @returns true if the password is valid, otherwise false
export const isValidPassword = (password) => {
  return regPassword.test(password.trim());
};

//This function takes the selected file as a parameter and returns true if the MIME type is an image, otherwise it returns false.
export const isImageFile = (files) => {
  const acceptedImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp"];

  return files.every(file => acceptedImageTypes.includes(file.type));
};
