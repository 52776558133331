import React from "react";

const CloseBtn = ({click}) => {
  
  return (
    <button
      className="btn-close"
      onClick={click}
    >
      Fermer
    </button>
  );
};

export default CloseBtn;
